/*------------------------------------------------------------------
[Fonts - Roboto]
*/

@include fontFace('Roboto','../../../../front/fonts/roboto/Roboto-Regular');
@include fontFace('RobotoItalic','../../../../front/fonts/roboto/Roboto-RegularItalic', italic, normal);

@include fontFace('RobotoRegular','../../../../front/fonts/roboto/Roboto-Regular', omrla, 400);
@include fontFace('RobotoRegularItalic','../../../../front/fonts/roboto/Roboto-RegularItalic', italic, 400);

@include fontFace('RobotoThin','../../../../front/fonts/roboto/Roboto-Thin', normal, 100);
@include fontFace('RobotoThinItalic','../../../../front/fonts/roboto/Roboto-ThinItalic', italic, 100);

@include fontFace('RobotoLight','../../../../front/fonts/roboto/Roboto-Light', normal, 300);
@include fontFace('RobotoLightItalic','../../../../front/fonts/roboto/Roboto-LightItalic', italic, 300);

@include fontFace('RobotoMedium','../../../../front/fonts/roboto/Roboto-Medium', normal, 500);
@include fontFace('RobotoMediumItalic','../../../../front/fonts/roboto/Roboto-MediumItalic', italic, 500);

@include fontFace('RobotoBold','../../../../front/fonts/roboto/Roboto-Bold', normal, 700);
@include fontFace('RobotoBoldItalic','../../../../front/fonts/roboto/Roboto-BoldItalic', italic, 700);

@include fontFace('RobotoBlack','../../../../front/fonts/roboto/Roboto-Black', normal, 900);
@include fontFace('RobotoBlackItalic','../../../../front/fonts/roboto/Roboto-BlackItalic', italic, 900);
