.leaflet-container {
  height: 100%;
  width: 100%;
  margin: 0 auto;
}

.leaflet-popup-content-wrapper {
  border-radius: 0 !important;
}

.leaflet-popup-content {
  margin: 16px 24px !important;

  a {
    color: $secondary-color !important;
  }
}

.leaflet-control-attribution{
  display: none !important;
}