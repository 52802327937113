@mixin role($bg-color) {
	background-color: $bg-color;
}

.super_admin{
	@include role($color-red);
}

.admin{
	@include role($color-blue);
}

.user{
	@include role($color-grey);
}

.operationnal{
	@include role($color-deep-orange);
}

.controller{
	@include role($color-cyan);
}

.rh{
	@include role($color-green);
}

.manager{
	@include role($color-deep-purple);
}

.maintenance{
	@include role($color-yellow);
}
