.messaging-notify {
  position: relative;
}

.messaging-notify[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: 3px;
  right: 2px;
  font-size: 10px;
  font-weight: 700;
  background: $secondary;
  color: white;
  width: 14px;
  height: 14px;
  text-align: center;
  border-radius: 50%;
  font-family: 'Roboto', sans-serif;
}

.messaging-notify.valid[data-badge]:after {
  background: $success-color;
}

.messaging-notify.invalid[data-badge]:after {
  background: $danger-color;

}