.card {
  margin-top: 10px;
  margin-bottom: 10px;

  > .progress {
    height: 0.1875rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1;

    .progress-bar {
      border-radius: .5rem 0 0 0;

      &[style="width: 100%"] {
        border-radius: .5rem .5rem 0 0;
      }
    }
  }
}

.card-sm {
  .card-body {
    padding: 1rem 1rem;
  }
}

.card-header .h2 {
  font-size: 1rem;
  text-transform: uppercase;
}

.tab-pane {

  .icon {
    display: inline-block;
    font-size: 15px;
    line-height: 25px;
    vertical-align: middle;
    cursor: pointer;
    color: #bababa;
    min-width: 20px;
    text-align: center;
  }

  h5 {
    font-size: 17px;
  }

}

.card-heading-divider {
  border-bottom: 1px solid #d9d9d9;
  margin: 0 20px 8px;
  padding-left: 0;
  padding-right: 0;
}

.card-table {
  border-color: #ddd;
  background-color: #fff;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, .04);
  border-width: 0;
  padding: 0;

  table {
    margin-bottom: 0;
  }
}

.card-header {

  button.tools {
    margin: 0;
    padding: 0;
  }

  .tools {
    float: right;

    .icon {
      display: inline-block;
      font-size: 18px;
      line-height: 25px;
      vertical-align: middle;
      cursor: pointer;
      color: $primary;
      min-width: 20px;
      text-align: center;
    }
  }

  .icon {
    color: $white;
  }

  h4,
  h3,
  h2,
  a.h4 {
    font-size: 0.95rem;
    padding: 5px;
    color: #444;
    font-weight: 300;
    text-transform: uppercase;
    margin: 0;
  }
}

.card-no-border {
  border: none;
}

.card-icon {
  font-size: 1.125rem;
  line-height: 1.4;
  opacity: .7;
  flex: 0 0 1.75rem;
}

[data-toggle="collapse"] .fa-collapse:before {
  content: "\f068";
}

[data-toggle="collapse"].collapsed .fa-collapse:before {
  content: "\f067";
}
