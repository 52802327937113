.btn-default{
  @include button-variant(#fff, #dedede);
}

.btn{
	font-size: 0.85rem;
	border-radius: 0px;
}

.btn-submit{
  @extend .btn-primary;
}

.btn-cancel{
@extend .btn-secondary;
}

.keyword{
	color: $secondary-color;
	font-style: italic;
	cursor: pointer;
}

.pointer{
  cursor: pointer;
}

.grabbable {
	cursor: move; /* fallback if grab cursor is unsupported */
	cursor: grab;
	cursor: -moz-grab;
	cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
	cursor: grabbing;
	cursor: -moz-grabbing;
	cursor: -webkit-grabbing;
}

.btn-primary{
	background-color:$primary;
	color: #fff;
	border-color:transparent;
}
.btn-secondary{
	background-color:$secondary;
	color: #fff;
	border-color:transparent;
}
.btn-header-bloc{
	background-color: $primary;
	color:#fff;
	margin: 5px;
}
.btn-actions{
  background-color: transparent;
  border-color: transparent;
  color:$secondary;
}