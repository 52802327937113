.required,
.required a {
  color: #B22222;
  font-weight: 700;
}

input[type="radio"], input[type="checkbox"] {
  width: 22px;
}

label.col-form-label {
  text-align: left;
  margin-bottom: 0;
  color: #666;
  font-size: 15px;
  @include media-breakpoint-up(lg) {
    text-align: right;
  }
}

.custom-control-label::after {
  top: .1rem
}

.custom-control-label::before {
  top: .1rem
}

.form-control {
  font-size: 0.9rem !important;
}

.action-legend {
  font-size: 1rem;
}

.custom-file-label{
  left: 15px;
  width: 90%;
}

.form-access-item{
  width: 10rem;
}

.custom-range::-webkit-slider-thumb {
  background: $secondary;
}

.custom-range::-moz-range-thumb {
  background: $secondary;
}

.custom-range::-ms-thumb {
  background: $secondary;
}

$checkpoint-states: () !default;
$checkpoint-states: map-merge(
                (
                        "red": (
                                "color": $color-red,
                                "icon": $form-feedback-icon-valid
                        ),
                        "pink": (
                                "color": $color-pink,
                                "icon": $form-feedback-icon-valid
                        ),
                        "purple": (
                                "color": $color-purple,
                                "icon": $form-feedback-icon-valid
                        ),
                        "deep-purple": (
                                "color": $color-deep-purple,
                                "icon": $form-feedback-icon-valid
                        ),
                        "indigo": (
                                "color": $color-indigo,
                                "icon": $form-feedback-icon-valid
                        ),
                        "blue": (
                                "color": $color-blue,
                                "icon": $form-feedback-icon-valid
                        ),
                        "light-blue": (
                                "color": $color-light-blue,
                                "icon": $form-feedback-icon-valid
                        ),
                        "cyan": (
                                "color": $color-cyan,
                                "icon": $form-feedback-icon-valid
                        ),
                        "teal": (
                                "color": $color-teal,
                                "icon": $form-feedback-icon-valid
                        ),
                        "green": (
                                "color": $color-green,
                                "icon": $form-feedback-icon-valid
                        ),
                        "light-green": (
                                "color": $color-light-green,
                                "icon": $form-feedback-icon-valid
                        ),
                        "lime": (
                                "color": $color-lime,
                                "icon": $form-feedback-icon-valid
                        ),
                        "yellow": (
                                "color": $color-yellow,
                                "icon": $form-feedback-icon-valid
                        ),
                        "amber": (
                                "color": $color-amber,
                                "icon": $form-feedback-icon-valid
                        ),
                        "orange": (
                                "color": $color-orange,
                                "icon": $form-feedback-icon-valid
                        ),
                        "deep-orange": (
                                "color": $color-deep-orange,
                                "icon": $form-feedback-icon-valid
                        ),
                ),
                $checkpoint-states
);

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $secondary;
  background-color: $secondary;
}


.custom-control-input:checked:disabled ~ .custom-control-label::before {
  border-color: transparent;
}

@each $state, $data in $checkpoint-states {
  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-#{$state} ~ .custom-control-label {
    color: map-get($data, color);
  }
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-#{$state}:checked ~ .custom-control-label::before {
    border-color: map-get($data, color);
    background-color: map-get($data, color);
  }
}

