.rstcustom__collapseButton:before {
  @extend .fas;
  content: fa-content($fa-var-angle-down);
  position: absolute;
  top: 0;
}

.rstcustom__expandButton:before {
  @extend .fas;
  content: fa-content($fa-var-angle-right);
  position: absolute;
  top: 0;
}