/*------------------------------------------------------------------
  [Helper classes]
*/
//Space helpers

@mixin makeSpace($prefix, $value) {
  .#{$prefix}-p-#{$value}   { padding:        #{$value}px !important; }
  .#{$prefix}-pt-#{$value}  { padding-top:    #{$value}px !important; }
  .#{$prefix}-pl-#{$value}  { padding-left:   #{$value}px !important; }
  .#{$prefix}-pr-#{$value}  { padding-right:  #{$value}px !important; }
  .#{$prefix}-pb-#{$value}  { padding-bottom: #{$value}px !important; }
  .#{$prefix}-m-#{$value}   { margin:         #{$value}px !important; }
  .#{$prefix}-mt-#{$value}  { margin-top:     #{$value}px !important; }
  .#{$prefix}-ml-#{$value}  { margin-left:    #{$value}px !important; }
  .#{$prefix}-mr-#{$value}  { margin-right:   #{$value}px !important; }
  .#{$prefix}-mb-#{$value}  { margin-bottom:  #{$value}px !important; }
}

// global
@include makeSpace(xs, 50);
@include makeSpace(xs, 40);
@include makeSpace(xs, 30);
@include makeSpace(xs, 25);
@include makeSpace(xs, 20);
@include makeSpace(xs, 15);
@include makeSpace(xs, 10);
@include makeSpace(xs, 5);
@include makeSpace(xs, 0);

// mobile
@include media-breakpoint-up(sm){
  @include makeSpace(sm, 50);
  @include makeSpace(sm, 40);
  @include makeSpace(sm, 30);
  @include makeSpace(sm, 25);
  @include makeSpace(sm, 20);
  @include makeSpace(sm, 15);
  @include makeSpace(sm, 10);
  @include makeSpace(sm, 5);
  @include makeSpace(sm, 0);
}

// tablet
@include media-breakpoint-up(md){
  @include makeSpace(md, 50);
  @include makeSpace(md, 40);
  @include makeSpace(md, 30);
  @include makeSpace(md, 25);
  @include makeSpace(md, 20);
  @include makeSpace(md, 15);
  @include makeSpace(md, 10);
  @include makeSpace(md, 5);
  @include makeSpace(md, 0);
}

// desktop
@include media-breakpoint-up(lg){
  @include makeSpace(lg, 200);
  @include makeSpace(lg, 150);
  @include makeSpace(lg, 100);
  @include makeSpace(lg, 50);
  @include makeSpace(lg, 40);
  @include makeSpace(lg, 30);
  @include makeSpace(lg, 25);
  @include makeSpace(lg, 20);
  @include makeSpace(lg, 15);
  @include makeSpace(lg, 10);
  @include makeSpace(lg, 5);
  @include makeSpace(lg, 0);
}
