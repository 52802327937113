.modal.fade .modal-dialog.modal-right {
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.modal.show .modal-dialog.modal-right {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.modal .modal-dialog.modal-right {
  right: 0;
}
.modal .modal-full-height {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  width: 400px;
  height: auto;
  min-height: 100%;
  margin: 0;
  .modal-body{
    overflow-y: auto;
  }
}
.modal .modal-full-height .modal-content {
  width: 100%;
  max-height: 100vh;
  border-radius: 0;
  overflow-y: hidden;
}