.jumbotron-upload-file {
  border: 2px dashed #c3c3c3;
  padding-top: 60px;
  padding-bottom: 60px;
}

.btn-upload-file {
  position: relative;
  overflow: hidden;
}

.btn-upload-file input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  -ms-filter: 'alpha(opacity=0)';
  direction: ltr;
  cursor: pointer;
}

.jumbotron-upload-file p.title {
  font-size: ceil($font-size-base * 2);
  font-weight: 500;
  line-height: 80px;
}

.jumbotron-upload-file p {
  font-size: ceil($font-size-base * 0.9);
}
