//Config variables
@import "includes/variables";

// NPM BOOTSTRAP
@import "~bootstrap/scss/bootstrap";

// NPM FONT AWESOME V5
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");

@import "~pc-bootstrap4-datetimepicker/src/sass/bootstrap-datetimepicker-build.scss";

//Mixins
@import "includes/mixins/background-variant.scss";
@import "includes/mixins/font.scss";

//General style
@import "includes/general.scss";

//Fonts
@import "includes/fonts/roboto.scss";

//Structure
@import "includes/structure/navbar/navbar_global.scss";
@import "includes/structure/navbar/navbar_fixed.scss";
@import "includes/structure/navbar/navbar_static.scss";
@import "includes/structure/navbar/navbar_sticky.scss";
@import "includes/structure/navbar/navbar_toggle.scss";
@import "includes/structure/navbar/navbar_colors.scss";
@import "includes/structure/footer.scss";
@import "includes/structure/page-actions.scss";

// Elements
@import "includes/elements/buttons.scss";
@import "includes/elements/cards.scss";
@import "includes/elements/charts.scss";
@import "includes/elements/colors.scss";
@import "includes/elements/badge.scss";
@import "includes/elements/multiselect.scss";
@import "includes/elements/timeline-compact.scss";
@import "includes/elements/timeline.scss";
@import "includes/elements/type.scss";
@import "includes/elements/uploader.scss";
@import "includes/elements/wizard.scss";
@import "includes/elements/datepicker.scss";
@import "includes/elements/calendar.scss";
@import "includes/elements/messaging.scss";
@import "includes/elements/modal.scss";
@import "includes/elements/avatar.scss";
@import "includes/elements/progress.scss";
@import "includes/elements/gantt.scss";

// Forms
@import "includes/forms/forms.scss";

// Specific pages style
@import "includes/pages/dashboard.scss";
@import "includes/pages/login.scss";
@import "includes/pages/resource.scss";
@import "includes/pages/errors.scss";

@import "includes/plugins/devbridge-autocomplete.scss";
@import "includes/plugins/react-big-calendar/styles";
@import "includes/plugins/leaflet";
@import "includes/plugins/signature";
@import "includes/plugins/tree";
@import "includes/plugins/gantt";
@import "~react-big-calendar/lib/addons/dragAndDrop/styles.css";

// Helper classes
@import "includes/helpers.scss";

// Modules
@import "includes/modules/user/roles.scss";
@import "includes/modules/platform/platform.scss";
