/*------------------------------------------------------------------
  [Dashboard]
*/
.dashboard{
  .number{
    font-size:50px;
    line-height: 90px;
    padding-right: 10px;
    font-weight: 300;
  }
  .icon-indicator{
    line-height: 90px;
    font-size: 50px;
    padding-left: 10px;
}
}