// Color support for .navbar-dark

#mainNav.navbar-dark {
  .navbar-collapse {
    .navbar-sidenav {
      .nav-link-collapse:after {
        color: $gray-600;
      }
      > .nav-item {
        > .nav-link {
          color: $gray-600;
          &:hover {
            color: $gray-500;
          }
        }
        .sidenav-second-level,
        .sidenav-third-level {
          > li > a {
            color: $gray-600;
            &:focus,
            &:hover {
              color: $gray-500;
            }
          }
        }
      }
    }
    .navbar-nav > .nav-item.dropdown > .nav-link:after {
      color: $gray-500;
    }
  }
  @media (min-width: 992px) {
    .navbar-collapse {
      .navbar-sidenav {
        background: $gray-800;
        li {
          &.active {
            a {
              color: white !important;
              background-color: $gray-700;
              &:focus,
              &:hover {
                color: white;
              }
            }
          }
        }
        > .nav-item {
          .sidenav-second-level,
          .sidenav-third-level {
            background: $gray-800;
          }
        }
      }
    }
  }
}

// Color support for .navbar-light

#mainNav.navbar-light {
  .navbar-collapse {
    .navbar-sidenav {
      .nav-link-collapse:after {
        color: fade-out($black, 0.5);
      }
      > .nav-item {
        > .nav-link {
          color: fade-out($black, 0.5);
          &:hover {
            color: fade-out($black, 0.3);
          }
        }
        .sidenav-second-level,
        .sidenav-third-level {
          > li > a {
            color: fade-out($black, 0.5);
            &:focus,
            &:hover {
              color: fade-out($black, 0.3);
            }
          }
        }
      }
    }
    .navbar-nav > .nav-item.dropdown > .nav-link:after {
      color: fade-out($black, 0.5);
    }
  }
  @media (min-width: 992px) {
    .navbar-collapse {
      .navbar-sidenav {
        background: $gray-100;
        li {
          &.active {
            a {
              color: $black !important;
              background-color: $gray-200;
              &:focus,
              &:hover {
                color: $black;
              }
            }
          }
        }
        > .nav-item {
          .sidenav-second-level,
          .sidenav-third-level {
            background: $gray-100;
          }
        }
      }
    }
  }
}

.nav.nav-tabs.dash-tabs{
  border: 1px solid rgba(0, 0, 0, 0.125);
  background-color: #fff!important;
  .nav-item{
    margin-bottom: -1px;
    .nav-link{
      color: #6b6b6b;
      font-size:0.85rem;
      text-transform: uppercase;
    }
    .nav-link:hover {
      border-color: transparent;
      background-color: #f8f8f8;
    }
  }
}
.nav.nav-tabs.dash-tabs .nav-item .nav-link.active{
  color: #6b6b6b;
  background-color: #fff;
  border-bottom: 2px solid $secondary-color;
  border-color: transparent transparent $secondary-color transparent;
  font-size:0.85rem;
  text-transform: uppercase;
  display: inline-block;
  &:hover{
    border-color: transparent transparent $primary-color transparent;
    border-bottom: 2px solid $primary-color;
    background-color: #f8f8f8;
  }
}
.nav.nav-tabs.dash-tabs.niveau-2 .nav-item .nav-link.active{
  border-bottom: 2px solid $secondary;
}

// Color support for .navbar-mf

#mainNav.navbar-mf {
  background-color: $white;
  border-bottom: 1px solid $gray-200;

  .navbar-nav .nav-link {
    color: fade-out($black, 0.3);
    font-weight: 300;
    &:hover {
      color: fade-out($black, 0.1);
    }
  }

  .navbar-collapse {
    .navbar-sidenav {
      .nav-link-collapse:after {
        color: fade-out($black, 0.3);
      }
      > .nav-item {
        > .nav-link {
          color: fade-out($black, 0.3);
          &:hover {
            color: fade-out($black, 0.1);
          }
        }
        .sidenav-second-level,
        .sidenav-third-level {
          > li > a {
            color: fade-out($black, 0.3);
            &:focus,
            &:hover {
              color: fade-out($black, 0.1);
            }
          }
        }
      }
    }
    .navbar-nav > .nav-item.dropdown > .nav-link:after {
      color: fade-out($black, 0.3);
    }
  }
  @media (min-width: 992px) {
    .navbar-collapse {
      .navbar-sidenav {
        background: $primary-color;
        li {
          &.active {
            a {
              color: #fff!important;
              font-weight: 300;
              background-color: $secondary;
              &:focus,
              &:hover {
                color: #fff!important;
                font-weight: 300;
              }
            }
          }
        }
        .nav-link-collapse:after {
          color: fade-out($white, 0.3);
        }
        > .nav-item {
          > .nav-link {
            color: fade-out($white, 0.3);
            &:hover {
              color: fade-out($white, 0.1);
            }
          }
          .sidenav-second-level,
          .sidenav-third-level {
            background: $primary-color;
            > li > a {
              color: fade-out($white, 0.3);
              &:focus,
              &:hover {
                color: fade-out($white, 0.1);
              }
            }
          }
        }
      }
    }
  }
}
