#page-actions .btn{
  margin-bottom: 10px;
  margin-right:0.5rem;

  @include media-breakpoint-up(sm) {
    margin-bottom: 0px;
  }
}


// Breadcrumbs

.breadcrumb{
  margin-bottom: 0;
  background-color: transparent;
}



