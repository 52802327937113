//$spacer: 1.5rem;

.avatar {
  width: $spacer*3;
  height: $spacer*3;
  border-radius: 50%;
  border: 2px solid $body-bg;
  background: $body-bg;
  color: $white;

  &.bg-secondary {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 1rem;
    }
  }

  &.avatar-lg {
    width: $spacer*4;
    height: $spacer*4;
  }

  &.avatar-sm {
    width: $spacer*2;
    height: $spacer*2;
  }

  &.avatar-xs {
    width: $spacer;
    height: $spacer;
  }
}

.card {
  .avatar {
    border-color: $white;
    background: $white;

    &.avatar-main {
      border-color: $secondary;
    }
  }
}

.avatars {
  padding-left: 0;
  list-style: none;
  margin: 0;

  > li {
    .avatar-up {
      z-index: 2;
    }

    display: inline-block;

    + li {
      margin-left: -$spacer/1.35;
    }
  }
}