//Color shades
$primary-color-dark:      darken($primary-color, 10%);
$primary-color-darkest:   darken($primary-color, 20%);
$primary-color-light:     lighten($primary-color, 10%);
$primary-color-lightest:  lighten($primary-color, 20%);

$danger-color-dark:       darken($danger-color, 10%);
$danger-color-darkest:    darken($danger-color, 20%);
$danger-color-light:      lighten($danger-color, 10%);
$danger-color-lightest:   lighten($danger-color, 20%);

$warning-color-dark:      darken($warning-color, 10%);
$warning-color-darkest:   darken($warning-color, 20%);
$warning-color-light:     lighten($warning-color, 10%);
$warning-color-lightest:  lighten($warning-color, 20%);

$success-color-dark:      darken($success-color, 10%);
$success-color-darkest:   darken($success-color, 20%);
$success-color-light:     lighten($success-color, 10%);
$success-color-lightest:  lighten($success-color, 20%);

$grey-color-dark:         darken($grey-color, 10%);
$grey-color-darkest:      darken($grey-color, 20%);
$grey-color-light:        lighten($grey-color, 10%);
$grey-color-lightest:     lighten($grey-color, 20%);

//Text contrast
$primary-color-text:      #5f99f5;
$success-color-text:      $success-color;
$info-color-text:         $info-color;
$warning-color-text:      $warning-color;
$danger-color-text:       $danger-color;
$grey-color-text:         darken($grey-color, 6%);
$dark-color-text:         #333333;

$color-red         : #D23B44;
$color-pink        : #D284D3;
$color-purple      : #A384D3;
$color-deep-purple : #5E3C92;
$color-indigo      : #50A3B0;
$color-blue        : #4DC9C3;
$color-light-blue  : #A5CAC8;
$color-cyan        : #00bcd4;
$color-teal        : #009688;
$color-green       : #A9CF3D;
$color-light-green : #B5CAA5;
$color-lime        : #cddc39;
$color-yellow      : #EEE15F;
$color-amber       : #ffc107;
$color-orange      : #EEB85F;
$color-deep-orange : #CF7D3D;
$color-brown       : #795548;
$color-grey        : #9e9e9e;
$color-blue-grey   : #607d8b;
$color-black       : #000000;
$color-white       : #ffffff;