// Paths
$img-path: "../../img";
$font-path: "../../fonts";
$components-path: "../../../../components";
$fa-font-path: "~@fortawesome/fontawesome-free/webfonts/";

// General colors palette
$primary: #282828;
$primary-color: $primary;
$secondary: #79b51d;
$secondary-color: $secondary;

$dark-color:  #3d3c3c;
$light-color: #f8f9fa;
$black-color: #000000;
$body-color:  #404040;
$text-color: #000000;

$body-bg: #f5f5f5;

// States Color
$danger-color:  #ea4335;
$warning-color: #fbbc05;
$success-color: #A9CF3D;
$grey-color:    #cccccc;
$info-color:    #4285f4;
$form-feedback-valid-color: #9ACD32;

// Icons colors
$icon-default-color: darken($light-color, 55%);
$icon-hover-color:   darken($light-color, 75%);

// Color variations
@import "colors.scss";

// General elements
$logo-width:                    180px;
$logo-height:                   27px;
$logo-padding:                  20px;
$body-background:               #f5f5f5;
$content-padding:               25px;
$content-padding-tablet:        25px;
$content-padding-phone:         15px;
$content-padding-small-phone:   10px;
$link-color:                    lighten($primary-color, 10%);
$scroller-size:                 6px;// Scroller plugin width

$custom-file-text: (
        en: "Browse",
        es: "Elegir",
        fr: "Parcourir"
);
// Typography

$roboto:           'Roboto', Arial, sans-serif;
$open-sans:      'Open Sans', sans-serif;
$raleway:		   'Raleway', sans-serif;

$font-weight-thin:          100;
$font-weight-light:         300;
$font-weight-regular:       400;
$font-weight-medium:        500;
$font-weight-bold:          700;
$font-weight-black:         900;

//Breadcrumbs
$breadcrumb-color:                darken($light-color, 46%);
$breadcrumb-active-color:         darken($breadcrumb-color, 30%);
$breadcrumb-bg:                   darken($light-color, 4%);
$breadcrumb-padding-vertical:     7px;
$breadcrumb-padding-horizontal:   20px;

$timeline-color: #dfdfdf;

//Charts
$chart-legend-color-size:     10px;
$chart-legend-padding:        6px;
$chart-pie-counter-size:      50px;

// Social colors
$facebook:      #5d82d1;
$twitter:       #50bff5;
$google-plus:   #eb5e4c;
$dribbble:      #f7659c;
$youtube:       #ef4e41;
$flickr:        #0063dc;
$linkedin:      #238cc8;
$pinterest:     #e13138;
$github:        #333;
$tumblr:        #426d9b;
$evernote:      #2DBE60;
$dropbox:       #007EE5;


// Spacing Variables
// Change below variable if the height of the navbar changes
$navbar-base-height: 56px;
// Change below variable to change the width of the sidenav
$sidenav-base-width: 250px;
// Change below variable to change the width of the sidenav when collapsed
$sidenav-collapsed-width: 55px;
// Change below variable to change the height of the sticky footer
$sticky-footer-height: 56px;
