// Global styling for this template

html {
  position: relative;
  min-height: 100%;
  font-family: 'Raleway', sans-serif;
}

body {
  overflow-x: hidden;
  background-color: $body-bg;
  font-family: 'Raleway', sans-serif;
}

body.sticky-footer {
  margin-bottom: $sticky-footer-height;

  .content-wrapper {
    min-height: calc(100vh - #{$sticky-footer-height} - #{$navbar-base-height});
    background-color: #ecedf3 !important;
  }
}

body.fixed-nav {
  padding-top: $navbar-base-height;
}

.content-wrapper {
  min-height: calc(100vh - #{$sticky-footer-height});
  background-color: transparent;
}

// Scroll to top button
.scroll-to-top {
  z-index: 100;
  position: fixed;
  right: 15px;
  bottom: 3px;

  display: none;

  width: 50px;
  height: 50px;

  text-align: center;

  color: white;
  background: fade-out($gray-800, .5);

  line-height: 60px;

  &:focus,
  &:hover {
    color: white;
  }

  &:hover {
    background: $gray-800;
  }

  i {
    font-weight: 800;
  }
}

.border-bottom {
  border-bottom: none !important;
}

.page-head {
  padding: 8px 24px;
  position: relative;
  background: #fff;
  border-bottom: 1px solid #eee;
}

.page-head .page-head-action {
  text-align: right;
  margin-top: 8px;
}

.page-head h1.h2 {
  display: block;
  margin-bottom: 5px;
  margin-top: 6px;
  font-weight: 300;
  letter-spacing: -1px;
  font-size: 1.3rem;
  color: #6b6b6b;
  line-height: 36px;
  text-transform: uppercase;
}

table thead th{
  border-top: 0px solid transparent !important;
  border-bottom: 1px solid #dee2e6 !important;
}

.legend-fieldset {
  font-size: 1rem;
  color: $secondary;
  padding-left: 10px;
}

.pagination {
  padding: 0;
  margin: 0;
}