.calendar-container {
  padding: 20px;
  height: 90vh;
}

.btn-calendar-legend {
  margin: 5px;
}

.rbc-toolbar {
  font-size: 14px;

  .rbc-btn-group {
    @extend .btn-group;

    button {
      @extend .btn, .btn-primary;

      &:focus,
      &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
      }

      .rbc-active {
        @include box-shadow($btn-active-box-shadow);

        &:focus {
          @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
        }
      }
    }
  }
}
