/*------------------------------------------------------------------
[Resource page style]
*/

.resource-title{
  min-height: 65px;
}

.resource-title a{
  font-size: 18px;
}

h2.card-title{
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

h3.card-title{
  text-transform: uppercase;
}

.list-resources {
  padding: 15px;
}

.list-border{
  padding-bottom: 5px;
  border-bottom: 1px dotted #444444;
}

.intervention-goal{
  color:#666;
  font-style: italic;
}

#fos_comment_thread {
  h3 {
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .fos_comment_comment_form_holder {
    h3 {
      font-size: 16px;
      font-weight: bold;
      text-transform: none;
    }
  }

  .fos_comment_comment_show{
    position: relative;
    background: #eeeeee;
    border-radius: 5px;
    padding:15px 80px 15px 15px;
    margin-bottom: 40px;
    min-height: 85px;

    .fos_comment_comment_metas {
      font-weight: 500;
    }
  }

  .fos_comment_comment_show:nth-child(odd):before{
    content:'';
    position: absolute;
    top: 100%;
    left: 80%;
    margin-left: -30px;
    margin-top: -30px;
    width: 0;
    height: 0;
    border-top: solid 30px transparent;
    border-right: solid 30px #eee;
    border-bottom: solid 30px transparent;
    border-left: solid 30px transparent;
  }

  .fos_comment_comment_show:nth-child(even):before{
    content:'';
    position: absolute;
    top: 100%;
    left: 20%;
    margin-left: -30px;
    margin-top: -30px;
    width: 0;
    height: 0;
    border-top: solid 30px transparent;
    border-right: solid 30px transparent;
    border-bottom: solid 30px transparent;
    border-left: solid 30px #eee;
  }

  .fos_comment_comment_show{
    position: relative;
  }

  .fos_comment_comment_body:after{
    @extend %fa-icon;
    @extend .fas;

    content: fa-content($fa-var-user-circle);
    position: absolute;
    top:10px;
    right:15px;
    font-size: 60px;
    color:#a157b3;
  }
}


.history-comment{
  color:#999;
}
